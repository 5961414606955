import MDinput from '@/components/MDinput';
import { validatorRequire, validatorRequireNumber, validatorConditionalRequired } from '@/utils/validators';
import Select from '@/components/DSE/Select';
import Switch from '@/components/DSE/Switch';
import NumericInput from '@/components/DSE/NumericInput';
import TextArea from '@/components/DSE/TextArea';
import { RibbonTypeOption, BlockTypeOption } from '@/utils/constants.js';
import PickRec from '@/components/DSE/PickRec';
import ImageInput from '@/components/DSE/ImageInput';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    position: {
      type: NumericInput,
      defaultValue: 0,
      props: {
        type: 'number',
        precision: 0,
        caption: 'common.position'
      }
    },
    blockType: {
      type: Select,
      defaultValue: '',
      props: {
        caption: 'homepageBlock.blockType',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('blockType'),
        required: true
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    content: {
      type: TextArea,
      props: {
        required: true,
        placeholder: 'homepageBlock.contentPlaceholder'
      },
      rules: [{ validator: validatorRequire }]
    },
    image: {
      type: ImageInput,
      props: {
        height: 75,
        caption: 'common.image'
      }
    },
    countryID: {
      type: Select,
      defaultValue: '',
      props: {
        caption: 'homepageBlock.country',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['country/isLoading'],
        options: vue.$store.getters['country/loadedItems'],
        required: true
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    ribbonType: {
      type: Select,
      defaultValue: RibbonTypeOption.None,
      props: {
        caption: 'homepageBlock.ribbonType',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('ribbonType')
      },
      dynamicProps: form => ({
        disabled: !blockTypeOptionsWithRibbon.includes(form.blockType)
      }),
      valueOnFormChange: (form, oldvalue) => {
        if (!blockTypeOptionsWithRibbon.includes(form.blockType)) {
          return RibbonTypeOption.None;
        }
        return oldvalue;
      }
    },
    ribbonText: {
      type: MDinput,
      props: {
        caption: 'homepageBlock.ribbonText',
        placeholder: 'homepageBlock.ribbonTextPlaceholder',
        maxlength: 25
      },
      dynamicProps: form => ({
        visible: form.ribbonType !== RibbonTypeOption.None
      }),
      valueOnFormChange: (form, oldvalue) => {
        if (form.ribbonType === RibbonTypeOption.None) {
          return null;
        }
        return oldvalue;
      },
      dynamicRules: form => [{ validator: validatorConditionalRequired(form.ribbonType !== RibbonTypeOption.None) }]
    },
    url: {
      type: MDinput,
      props: {
        caption: 'homepageBlock.url',
        type: 'url',
        maxlength: 264,
        required: false
      }
    },
    restaurantID: {
      type: PickRec,
      defaultValue: null,
      props: {
        caption: 'common.restaurant',
        valueField: 'id',
        labelField: 'name',
        inputField: 'name',
        maxlength: 64,
        columns: [
          { field: 'name', label: 'common.restaurant' },
          { field: 'code', label: 'common.code' }
        ],
        entity: 'Restaurant',
        required: false,
        usePagination: true,
        hideInput: false,
        suggest: true
      }
    },
    onlineYN: {
      type: Switch,
      defaultValue: false,
      props: {
        activeText: 'common.yes',
        inactiveText: 'common.no'
      }
    }
  };
}

const blockTypeOptionsWithRibbon = [
  BlockTypeOption.Specials1,
  BlockTypeOption.Specials2,
  BlockTypeOption.Specials3,
  BlockTypeOption.SpecialsApp1,
  BlockTypeOption.SpecialsApp2,
  BlockTypeOption.SpecialsApp3,
  BlockTypeOption.SpecialsLocal,
  BlockTypeOption.SpecialsAppLocal
];

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
